import { getApp } from 'app'
import { atom, selector } from 'recoil'
const { masterInterfaceSystem } = getApp()


interface MasterInterfaceState {
	selectedSoundset?: MasterInterface.Soundset
	selectedMood?: MasterInterface.Mood
	selectedElement?: MasterInterface.Element
}


export const masterInterfaceAtom = atom<MasterInterfaceState>({
	key: 'masterInterfaceStateAtom',
	default: {
		selectedSoundset: undefined,
		selectedMood: undefined,
		selectedElement: undefined
	}
})

export const masterInterfaceConnectionSelector = selector<boolean>({
	key: 'masterInterfaceConnectionSelector',
	// get: async () => { await wait(10000); return false }
	get: async () => await masterInterfaceSystem.connectAndAuthorize()
})