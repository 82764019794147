import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter'
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch'
import { faSortAlphaDown } from '@fortawesome/pro-regular-svg-icons/faSortAlphaDown'
import { faTh } from '@fortawesome/pro-regular-svg-icons/faTh'
import { faList } from '@fortawesome/pro-regular-svg-icons/faList'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle'
import { faPlayCircle } from '@fortawesome/pro-regular-svg-icons/faPlayCircle'
import { faStopCircle } from '@fortawesome/pro-regular-svg-icons/faStopCircle'
import { faStop } from '@fortawesome/pro-solid-svg-icons/faStop'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle'
import { faExternalLinkSquareAlt } from '@fortawesome/pro-regular-svg-icons/faExternalLinkSquareAlt'
import { faAngleDoubleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDoubleDown'
import { faAngleDoubleUp } from '@fortawesome/pro-regular-svg-icons/faAngleDoubleUp'
import { faBullseyeArrow } from '@fortawesome/pro-regular-svg-icons/faBullseyeArrow'
import { faVolume } from '@fortawesome/pro-regular-svg-icons/faVolume'
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser'
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers'


// from "@fortawesome/pro-light-svg-icons";
// from "@fortawesome/pro-solid-svg-icons";
// from "@fortawesome/pro-duotone-svg-icons";

export const iconMap = {
	filter: faFilter,
	search: faSearch,
	sort: faSortAlphaDown,
	tileView: faTh,
	listView: faList,
	info: faInfoCircle,
	openExternal: faExternalLinkSquareAlt,
	play: faPlayCircle,
	stop: faStop,
	stopCircle: faStopCircle,
	add: faPlusCircle,
	twirlDown: faAngleDoubleDown,
	twirlUp: faAngleDoubleUp,
	oneshot: faBullseyeArrow,
	volume: faVolume,
	user: faUser,
	users: faUsers,
}
