import { FontAwesomeIconName } from 'modules-ui/search'
import { iResultCategory, iResultModelHumanName, iResultStatus, iSearchResult } from '../searchTypes'
import { TagKey, tagMap } from './tagMap'



export const elementModelHumanNames: iResultModelHumanName[] =
	['Music', 'Loops', 'SFX', 'Oneshots']

export const moodModelHumanName: iResultModelHumanName = 'Moods'
export const sampleModelHumanName: iResultModelHumanName = 'Samples'

const playableModelHumanNames: iResultModelHumanName[] =
	['Moods', 'Music', 'Loops', 'SFX', 'Oneshots', 'Samples']


export const isPlayable = (result: iSearchResult) =>
	playableModelHumanNames.includes(result.model_human_name)


const categoryTagMap: Record<iResultCategory, TagKey> = {
	'General Fantasy': TagKey.Genre_GeneralFantasy,
	'DUNGEONS & DRAGONS': TagKey.Genre_DungeonsAndDragons,
	'Pathfinder': TagKey.Genre_Pathfinder,
	'Sci-Fi': TagKey.Genre_SciFi,
	'BoardGame': TagKey.Genre_BoardGame,
}


export const titleTagMap: Record<string, TagKey> =
	Object.values(tagMap)
		.reduce((acc, { name, key }) => ({ ...acc, [name]: key })
			, {} as any)
export const fontAwesomeIconNameTagMap: Record<FontAwesomeIconName, TagKey> =
	Object.values(tagMap)
		.reduce((acc, { fontAwesomeName, key }) => ({ ...acc, [fontAwesomeName]: key })
			, {} as any)

//order is important here, go from dnd (specific) to boardgame (general)
export const getTagFromCategories = (categories: iResultCategory[]): TagKey | undefined =>
	Object
		.entries(categoryTagMap)
		.find(([key]) => categories.includes(key as iResultCategory))?.[1]

export const resultKindMap: Record<iResultModelHumanName, TagKey> = {
	'Adventures': TagKey.Kind_Adventure,
	'Chapters': TagKey.Kind_Soundset,
	'Loops': TagKey.Kind_Loop,
	'Moods': TagKey.Kind_Mood,
	'Music': TagKey.Kind_Music,
	'Oneshots': TagKey.Kind_Oneshot,
	'SFX': TagKey.Kind_SFX,
	'Samples': TagKey.Kind_Sample
}

export const resultStatusMap: Record<iResultStatus, TagKey> = {
	'COMMUNITY': TagKey.Status_CommunityContent,
	'OFFICIAL': TagKey.Status_OfficialContent,
	'PRIVATE': TagKey.Status_MyContent,
	'PENDING_APPROVAL': TagKey.Status_PendingReview,
}

// export const getTagFromStatus=(status:iResultStatus)=>
