import React from 'react'
import { SearchItemList } from './SearchItemList'
import { Typography } from '@mui/material'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import InfiniteScroll from 'react-infinite-scroller'
import { getApp } from 'app'
import { NextPageTrigger, SearchResponseState } from '../searchState'
import { SearchResultsEndMessage } from './SearchResultsEndMessage'
import { css } from '@emotion/css'

interface Props {
  height?: number
}
export const SearchResultsList = ({ height }: Props) => {
	const searchResponse = useRecoilValue(SearchResponseState)
	const set_nextPage = useSetRecoilState(NextPageTrigger)

	if (!searchResponse) return null

	//TODO use a hash as key for each result, re-render on changed data
	const resultsList = searchResponse.results.map((item, index) => (
		<SearchItemList key={`${item.pk}-${item.title}-${item.model_name}-${item.playing_now}`} item={item} />
	))

	return (
		<InfiniteScroll
			useWindow={false}
			threshold={200}
			loadMore={() => set_nextPage((prev) => ++prev)}
			hasMore={searchResponse.more_pages}
			getScrollParent={() => document.querySelector('.right-col.search')}
			// loader={<Box m={3}><SimpleCircularProgress /></Box>} //currently too cpu intensive for spinner...
			loader={
				<Typography
					key={'LoadingIndicator'}
					align={'center'}
					variant={'body1'}
					margin={'10px 0'}
					fontSize={'1.5rem'}
				>
					Loading...
				</Typography>
			}
			style={{fontSize: '1.6rem'}}
		>
			{resultsList}
			{!searchResponse.more_pages && (
				<SearchResultsEndMessage key={'SearchResultsEndMessage'} />
			)}
		</InfiniteScroll>
	)
}
