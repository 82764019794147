import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getApp } from 'app'
import { iSearchResult } from 'modules-core/searchSystem'
import { SimpleTooltip, iconMap } from 'modules-ui/ui'
import { SimpleHtmlTypography } from 'modules-ui/ui/SimpleHtmlTypography'
import { UseStyles } from 'modules-ui/utility/styleUtility'
import React from 'react'
import { SearchItemSummary } from './SearchItemSummary'
import { highlightOrRegular } from './utility'


const { masterInterfaceSystem, urlSystem } = getApp()

interface iProps {
    item: iSearchResult
}


export const SearchItemTitle = ({ item }: iProps) => {
	const title = highlightOrRegular(item, 'title')
	const styles = useStyles()

	const handleClick = () =>
		item.available_to_buy
			? window.open(urlSystem.createUrlHttp('subscriptions/3-supersyrin/'))
			: masterInterfaceSystem.openActionMap[item.model_name](item)

	return masterInterfaceSystem?.openActionMap[item.model_name]
		? <div
			className={`${styles.container} ${styles.containerClickable}`}
			onClick={handleClick}>
			<div className={styles.titleRow}>
				<SimpleHtmlTypography text={title} />
				<span className={styles.padding} />
				<SimpleTooltip title='Open'>
					<div>
						<FontAwesomeIcon className={styles.openIcon} icon={iconMap.openExternal} />
					</div>
				</SimpleTooltip>
			</div>
			<SearchItemSummary item={item} />
		</div>
		: <div className={styles.container}>
			<div className={styles.titleRow}>
				<SimpleHtmlTypography text={title} />
			</div>
			<SearchItemSummary item={item} />
		</div>
}

const useStyles = UseStyles(theme => ({
	container: {
		margin: '0.2em',
		padding: '0.2em',
		borderRadius: '0.6em',
		cursor: 'default',
		display: 'flex',
		// alignItems: 'center',
		flexDirection: 'column',
		flexGrow: 1,
	},
	titleRow: {
		flexGrow: 1,
		alignItems: 'center',
		display: 'flex',
	},
	containerClickable: {
		cursor: 'pointer',
		transition: 'all .1s ease-in-out',
		'&:hover': {
			backgroundColor: theme.palette.background.paper,
		}
	},
	openIcon: {
		color: theme.palette.primary.main
	},
	padding: {
		paddingLeft: '0.8em'
	},
}))
