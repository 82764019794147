import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input, InputAdornment, CircularProgress } from '@mui/material'
import React from 'react'
import { useRecoilState } from 'recoil'
import { searchQueryAtom } from '../searchState'
import { setCyid } from 'modules-ui/utility/testUtility'
import { iconMap } from 'modules-ui/ui/iconMap'

import { useRecoilValue } from 'recoil'
import { searchLoadingAtom } from '../searchState'
import { createReactDebounce } from 'modules-ui/utility/reactDebounce'


export const SearchInput = () => {

	const searchLoading = useRecoilValue(searchLoadingAtom)
	const [searchQuery, set_searchQuery] = useRecoilState(searchQueryAtom)
	const [text, set_text] = React.useState('')

	React.useEffect(() => {
		if (text !== searchQuery && typeof searchQuery === 'string')
			set_text(searchQuery)
	}, [searchQuery])

	createReactDebounce(() => set_searchQuery(text), text, 500)

	return (
		<Input
			{...setCyid('search-bar-input')}
			style={{ width: '100%', fontSize: '1.6rem' }}
			value={text}
			onChange={e => set_text(e.target.value)}
			placeholder="Search..."
			startAdornment={
				<InputAdornment position="start">
					<FontAwesomeIcon icon={iconMap.search} />
				</InputAdornment>}
			endAdornment={searchLoading ?
				<InputAdornment position="end" >
					<CircularProgress size={22} />
				</InputAdornment>
				: null}
		/>
	)
}
