import { createInstanceGetter } from 'modules-core/utility'
import { iApp, iCreateApp } from './appTypes'




let hasBeenSet = false
let createApp = (): iApp => {
	throw new Error('no app specified, either the app setter has not been listed in the package.json sideEffects[] or hasnt been imported one in the top level of your code, ie: \nexample -- import \'app/common/setBaseApp --\';')
}
const createAppWrapper = () => createApp()


export const getApp = createInstanceGetter(createAppWrapper)

export const setCreateApp = (createAppFunc: iCreateApp) => {
	if (hasBeenSet)
		throw new Error('App has already been set, please only have one `import app/setApp...` in your code')
	hasBeenSet = true
	createApp = createAppFunc
}