import { css } from '@emotion/css'
import { createTheme, Theme, ThemeProvider, StyledEngineProvider, GlobalStyles } from '@mui/material'
import { baselineStyles } from 'modules-ui/theme/baselineStyles'
import { palette } from 'modules-ui/theme/palette'
import React from 'react'
import { atom, useRecoilValue, useSetRecoilState } from 'recoil'

/*
color tool:		https://material.io/resources/color/#!/?view.left=0&view.right=0
my theme:		https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=81C784&secondary.color=FFAB91
*/
const appThemeState = atom({
	key: 'appThemeState',
	default: {
		darkMode: true,
		menuDrawerOpen: false,
		menuDrawerPermanent: true,
	}
})

interface ThemeProps {
	darkMode: boolean
}

const titlePadding = '0.1em 1em'

function useAppTheme(): Theme {

	const { darkMode } = useRecoilValue(appThemeState)
	const background = darkMode
		? palette.background.dark
		: palette.background.light


	const paperOverride = {
		styleOverrides: {
			root: {
				backgroundColor: background.paper
			}
		}
	}
	return createTheme({
		palette: {
			// mode: props.globalState.providers.localStorage?.data.darkMode ? "dark" : "light",
			mode: darkMode ? 'dark' : 'light',
			primary: palette.primary,
			secondary: palette.secondary,
			background,
		},
		typography: {
			fontFamily: 'Homenaje',
			h1: {
				padding: titlePadding,
			},
			h2: {
				padding: titlePadding,
			},
			h3: {
				padding: titlePadding,
			},
			h4: {
				padding: titlePadding,
			},
			h5: {
				padding: titlePadding,
			},
			h6: {
				padding: titlePadding,
			},
		},
		components: {
			MuiButton: {
				defaultProps: {
					color: 'inherit',
				},
			},
			MuiSelect: {
				defaultProps: {
					variant: 'standard',
				},
			},
			MuiTextField: {
				defaultProps: {
					variant: 'standard',
				},
			},
			MuiSlider: {
				defaultProps: {
					size: 'small',
				},
			},
			MuiIconButton: {
				defaultProps: {
					size: 'small',
				},
			},
			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						fontSize: '1.1rem',
					},
				},
			},
			MuiAccordionSummary: paperOverride,
			MuiAccordion: paperOverride,
			MuiAccordionDetails: paperOverride,
		},
	})
}

const windowPersistentDrawerWidth = 800

const AppTheme = (props: any) => {

	const set_appTheme = useSetRecoilState(appThemeState)

	React.useEffect(() => {
		function handleResize() {
			set_appTheme(prev => {

				const menuDrawerPermanent = window.innerWidth >= windowPersistentDrawerWidth
				let menuDrawerOpen = prev.menuDrawerOpen
				if (prev.menuDrawerPermanent && !menuDrawerPermanent)
					menuDrawerOpen = false
				else if (!prev.menuDrawerPermanent && menuDrawerPermanent)
					menuDrawerOpen = true
				return {
					...prev,
					menuDrawerOpen,
					menuDrawerPermanent
				}
			})
		}
		window.addEventListener('resize', handleResize)
		//CALL ON INIT
		handleResize()
		if (window.innerWidth >= windowPersistentDrawerWidth)
			set_appTheme(state => ({ ...state, menuDrawerOpen: true }))

		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return (
		<StyledEngineProvider injectFirst>
			<GlobalStyles styles={baselineStyles} />
			<ThemeProvider theme={useAppTheme()}>
				{props.children}
			</ThemeProvider>
		</StyledEngineProvider>
	)
}

export default AppTheme

export {
	AppTheme,
	appThemeState,
	ThemeProps,
}
