



import { IconButton } from '@mui/material'
import { ArrowDownIcon, ArrowUpIcon } from 'modules-ui/utility'
import React from 'react'
import { SimpleTooltip } from './SimpleTooltip'

interface iProps {
    open: boolean
    set_open: React.Dispatch<React.SetStateAction<boolean>>
    variant?: 'search'
}



export const SimpleExpandButton = ({ open, set_open, variant }: iProps) => {
	const title = open ? 'Less Details' : 'More Details'
	const searchStyles: React.CSSProperties = {
		width: '24px',
		height: '24px',
	}

	return (
		<SimpleTooltip title={title}>
			<IconButton size="small" onClick={() => set_open((state) => !state)}>
				{open ? (
					<ArrowUpIcon style={variant && searchStyles} />
				) : (
					<ArrowDownIcon style={variant && searchStyles} />
				)}
			</IconButton>
		</SimpleTooltip>
	)
}
