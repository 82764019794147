import {
	css as emoCss,
	cx as emoCx,
	CSSInterpolation
} from '@emotion/css'
import { Theme, useTheme } from '@mui/material'

//https://github.com/Microsoft/typescript-styled-plugin#tags
//keywords: styled, css, injectGlobal, keyframes, createGlobalStyle
export const createGlobalStyle = (val: TemplateStringsArray) => val.join('')

export const combineStyles = (...args: string[]) => args.join(' ')


type CreateStylesArgs<T> = {
	[P in keyof T]: CSSInterpolation
}
type CreatedStyles<T> = {
	[P in keyof T]: string
}

type UseStyles = <T>(func: (theme: Theme) => CreateStylesArgs<T>) => () => CreatedStyles<T>
// type Style = (style: Array<CSSInterpolation> | TemplateStringsArray) => string

const createStyles = <T>(args: CreateStylesArgs<T>) =>
	Object.entries(args)
		.reduce((acc, curr) => {
			acc[curr[0]] = emoCss(curr[1] as any)
			return acc
		}, {} as CreatedStyles<T>)

export const css = emoCss
export const cx = emoCx
// export const Style = css
export const Styles = createStyles
export const UseStyles: UseStyles = func =>
	() => createStyles(func(useTheme()))

// const styles = UseStyles(() => ({ pow: { 'background': '#dddddd' } }))()
// styles.pow