import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, } from '@mui/material'
import { setCyid } from 'modules-ui/utility/testUtility'
import { SimpleTooltip } from 'modules-ui/ui'
import { getApp } from 'app'
import { iconMap } from 'modules-ui/ui/iconMap'
import { iSearchResult } from 'modules-core/searchSystem'
import { masterInterfaceAtom } from 'modules-ui/masterInterface'
import { useRecoilValue } from 'recoil'

const { masterInterfaceSystem } = getApp()

interface iProps {
    item: iSearchResult
    buttonProps?: any
}



const AddButton = ({ item, buttonProps }: iProps) =>
	<SimpleTooltip title="Add">
		<IconButton
			{...setCyid('search-item-add-button')}
			size="medium"
			onClick={() => masterInterfaceSystem.addActionMap[item.model_name](item)}
			{...buttonProps}>
			<FontAwesomeIcon icon={iconMap.add} style={{fontSize: '1.2em'}} />
		</IconButton>
	</SimpleTooltip>

export const SearchItemAdd = ({ item, buttonProps }: iProps) => {
	const masterInterfaceState = useRecoilValue(masterInterfaceAtom)
	switch (item.model_name) {
	case 'SoundSample':
		return masterInterfaceState.selectedElement?.can_edit
			? <AddButton item={item} buttonProps={buttonProps} />
			: null
	case 'Mood':
	case 'MusicElement':
	case 'SFXElement':
	case 'OneshotElement':
		return masterInterfaceState.selectedSoundset?.user_can_edit_soundset
			? <AddButton item={item} buttonProps={buttonProps} />
			: null
	default:
		return null
	}
}
