import { FontAwesomeIconName } from 'modules-ui/search'
import { TagTypeKey } from './tagTypeMap'




export interface iTag {
	name: string
	fontAwesomeName: FontAwesomeIconName
	tagType: TagTypeKey
	key: TagKey
}

export type iTagMap = Record<TagKey, iTag>

export enum TagKey {
	Genre_GeneralFantasy,
	Genre_DungeonsAndDragons,
	Genre_Pathfinder,
	Genre_SciFi,
	Genre_BoardGame,
	Kind_Adventure,
	Kind_Soundset,
	Kind_Mood,
	Kind_Music,
	Kind_Loop,
	Kind_SFX,
	Kind_Oneshot,
	Kind_Sample,
	Library_AvailableToPlay,
	Library_AvailableToBuy,
	Playing_NowPlaying,
	Status_OfficialContent,
	Status_CommunityContent,
	Status_MyContent,
	Status_PendingReview
}

export const stateFromIndices = (indices?: number[]) =>
	tagMapArray
		.map(tag => !!indices?.some(i => i === tag.key))

export const activeTagsFromState = (state: boolean[]) =>
	tagMapArray
		.filter((t, i) => state[i] === true)

export const tagMap: iTagMap = {
	[TagKey.Genre_GeneralFantasy]: {
		name: 'General Fantasy',
		fontAwesomeName: 'swords',
		tagType: TagTypeKey.Genre,
		key: TagKey.Genre_GeneralFantasy,
	},
	[TagKey.Genre_DungeonsAndDragons]: {
		name: 'Dungeons & Dragons',
		fontAwesomeName: 'd-and-d',
		tagType: TagTypeKey.Genre,
		key: TagKey.Genre_DungeonsAndDragons
	},
	[TagKey.Genre_Pathfinder]: {
		name: 'Pathfinder',
		fontAwesomeName: 'bow-arrow',
		tagType: TagTypeKey.Genre,
		key: TagKey.Genre_Pathfinder
	},
	[TagKey.Genre_SciFi]: {
		name: 'Sci-Fi',
		fontAwesomeName: 'raygun',
		tagType: TagTypeKey.Genre,
		key: TagKey.Genre_SciFi
	},
	[TagKey.Genre_BoardGame]: {
		name: 'Board Game',
		fontAwesomeName: 'chess',
		tagType: TagTypeKey.Genre,
		key: TagKey.Genre_BoardGame
	},
	[TagKey.Kind_Adventure]: {
		name: 'Adventures',
		fontAwesomeName: 'book-open',
		tagType: TagTypeKey.Kind,
		key: TagKey.Kind_Adventure
	},
	[TagKey.Kind_Soundset]: {
		name: 'Soundsets',
		fontAwesomeName: 'album-collection',
		tagType: TagTypeKey.Kind,
		key: TagKey.Kind_Soundset
	},
	[TagKey.Kind_Mood]: {
		name: 'Moods',
		fontAwesomeName: 'list-music',
		tagType: TagTypeKey.Kind,
		key: TagKey.Kind_Mood
	},
	[TagKey.Kind_Music]: {
		name: 'Music',
		fontAwesomeName: 'music',
		tagType: TagTypeKey.Kind,
		key: TagKey.Kind_Music
	},
	[TagKey.Kind_Loop]: {
		name: 'Loops',
		fontAwesomeName: 'infinity',
		tagType: TagTypeKey.Kind,
		key: TagKey.Kind_Loop
	},
	[TagKey.Kind_SFX]: {
		name: 'SFX',
		fontAwesomeName: 'waveform',
		tagType: TagTypeKey.Kind,
		key: TagKey.Kind_SFX
	},
	[TagKey.Kind_Oneshot]: {
		name: 'Oneshots',
		fontAwesomeName: 'bullseye-arrow',
		tagType: TagTypeKey.Kind,
		key: TagKey.Kind_Oneshot
	},
	[TagKey.Kind_Sample]: {
		name: 'Samples',
		fontAwesomeName: 'file-audio',
		tagType: TagTypeKey.Kind,
		key: TagKey.Kind_Sample
	},
	[TagKey.Library_AvailableToPlay]: {
		name: 'Available to Play',
		fontAwesomeName: 'volume',
		tagType: TagTypeKey.Library,
		key: TagKey.Library_AvailableToPlay
	},
	[TagKey.Library_AvailableToBuy]: {
		name: 'Available to Buy',
		fontAwesomeName: 'shopping-cart',
		tagType: TagTypeKey.Library,
		key: TagKey.Library_AvailableToBuy
	},
	[TagKey.Playing_NowPlaying]: {
		name: 'Now Playing',
		fontAwesomeName: 'toggle-on',
		tagType: TagTypeKey.Playing,
		key: TagKey.Playing_NowPlaying
	},
	[TagKey.Status_OfficialContent]: {
		name: 'Official Content',
		fontAwesomeName: 'check',
		tagType: TagTypeKey.Status,
		key: TagKey.Status_OfficialContent
	},
	[TagKey.Status_CommunityContent]: {
		name: 'Community Content',
		fontAwesomeName: 'users',
		tagType: TagTypeKey.Status,
		key: TagKey.Status_CommunityContent
	},
	[TagKey.Status_MyContent]: {
		name: 'My Content',
		fontAwesomeName: 'user-lock',
		tagType: TagTypeKey.Status,
		key: TagKey.Status_MyContent
	},
	[TagKey.Status_PendingReview]: {
		name: 'Pending Review',
		fontAwesomeName: 'cloud-upload-alt',
		tagType: TagTypeKey.Status,
		key: TagKey.Status_PendingReview
	}
}


export const tagMapArray = Object.values(tagMap)