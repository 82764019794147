import React from 'react'
import { RecoilRoot } from 'recoil'
import { CssBaseline } from '@mui/material'
import { AppTheme } from 'modules-ui/theme'

interface iProps {
    children: React.ReactNode
}



export const AppBase = ({ children }: iProps) => {
	return (
	//react-dom.development.js:67 Warning: findDOMNode is deprecated in StrictMode. findDOMNode was passed an instance of Transition which is inside StrictMode. Instead, add a ref directly to the element you want to reference. Learn more about using
	// < React.StrictMode >

		<RecoilRoot>
			<link rel="stylesheet" href="/static/homenaje/index.css" />
			<AppTheme>
				<CssBaseline />
				{children}
			</AppTheme>
		</RecoilRoot>
	)
}
