import { css, cx } from '@emotion/css'
import React from 'react'



interface Props {
  children?: React.ReactNode
  variant?: 'default' | 'playing' | 'disabled'
}

const GradientIconBackground = ({ children, variant }: Props) => {
	variant ??= 'default'
	return <div className={styleMap[variant]}>
		{children}</div>
}


const backgroundStyle = css`
  background-color: rgb(229, 229, 229);
  background-image: linear-gradient(75deg, rgb(144, 144, 144) 0%, rgb(229, 229, 229) 66%);
  border-color: rgb(48, 48, 48);
  border-radius: 50%;
  color: rgb(48, 48, 48);
  text-align: center;
  width: 2.25em;
  height: 2.25em;
  display: grid;
  place-items: center;
  `

const playingMod = css`
  background-color: #0f9ed6;
  background-image: linear-gradient(75deg, #87ceea 0%, #0f9ed6 66%);
  border-color: #e7f5fb;
  color: #e7f5fb;
`

const disabledMod = css`
  opacity: 0.5;
  `

const disabledStyle = cx(backgroundStyle, disabledMod)
const playingStyle = cx(backgroundStyle, playingMod)

const styleMap = {
	'default':backgroundStyle,
	'disabled':disabledStyle,
	'playing':playingStyle
}


export default GradientIconBackground
