import { Box, Grid } from '@mui/material'
import { fontAwesomeIconNameTagMap, iSearchResult } from 'modules-core/searchSystem'
import { SimpleHtmlTypography } from 'modules-ui/ui/SimpleHtmlTypography'
import { css } from 'modules-ui/utility/styleUtility'
import React from 'react'
import { highlightOrRegular } from './utility'
import { TagIcon } from './SearchItemTags'

interface iProps {
item: iSearchResult
}
export const SearchItemInfoContent = ({ item }: iProps) => {
	const fields = [
		'Title', highlightOrRegular(item, 'title'),
		'Type', highlightOrRegular(item, 'model_human_name'),
	]

	if (item.category && item.category.length > 0)
		fields.push('Categories', highlightOrRegular(item, 'category'))

	if (item.status)
		fields.push('Status', highlightOrRegular(item, 'status'))

	if (item.attribution)
		fields.push('Attribution', highlightOrRegular(item, 'attribution'))

	if (item.description)
		fields.push('Description', highlightOrRegular(item, 'description'))

	const relatedData = item.meta?.highlight?.related_data.map(([title, faIcon, tagTitle], i) =>
		<Grid item key={i} xs={6} sm={4}>
			<TagIcon tagKey={fontAwesomeIconNameTagMap[faIcon]} />
			<SimpleHtmlTypography className={style} text={title} />
		</Grid>)


	return <Box m={2}>
		<Grid container spacing={1}>
			{fields.map((f, i) =>
				i % 2 == 0
					? <Grid item key={i} xs={3}><span className={style}>{f}</span></Grid>
					: <Grid item key={i} xs={9}><SimpleHtmlTypography className={style} text={f} /></Grid>
			)}
			{relatedData?.length > 0 &&
			<>
				<Grid item xs={12}><span className={style}>Related Data</span></Grid>
				{relatedData}
			</>
			}
		</Grid>
	</Box>
}

const style = css`
font-size:0.9em;
`
