import React from 'react'

export const createReactDebounce = (func, stateToWatch: any, delay = 1000, runOnMount = false) => {
	const [timeoutHandle, set_timeoutHandle] = React.useState(null)
	const [firstTrigger, set_firstTrigger] = React.useState(runOnMount)
	React.useEffect(() => {
		if (firstTrigger === false) {
			set_firstTrigger(true)
			return
		}
		let recentHandle
		set_timeoutHandle(state => {
			state && clearTimeout(state)
			recentHandle = setTimeout(func, delay)
			return recentHandle
		})
		return () => {
			timeoutHandle && clearTimeout(timeoutHandle)
			recentHandle && clearTimeout(recentHandle)
		}
	}, [stateToWatch])
}