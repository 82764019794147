


export const palette = {
	primary: {
		main: '#0f9ed6',
		light: '#62cfff',
		dark: '#0070a4'
	},
	secondary: {
		main: '#7b1fa2',
		light: '#ae52d4',
		dark: '#4a0072'
	},
	text: {
		primary: 'rgba(0, 0, 0, 0.87)',
		secondary: 'rgba(0, 0, 0, 0.54)',
		tertiary: 'rgba(0, 0, 0, 0.38)'
	},
	background: {
		light: {
			default: '#ffffff',
			paper: '#dddddd',
		},
		dark: {
			default: '#303030',
			paper: '#424242'
		}
	}
}