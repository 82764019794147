
import { AuthenticationError, iAuthSystem, } from 'modules-core/authSystem'
import { iSearchResponse } from 'modules-core/searchSystem/searchTypes'
// import { createSortSystem } from 'modules-core/searchSystem/sortSystem';
import { createElasticFilterSystem } from './elasticFilterSystem'
import { iTag, stateFromIndices, tagMapArray } from './tags/tagMap'
import { createQueryBuilder } from './queryBuilder'
import { dispatchEventWithArgs } from 'modules-core/eventSystem'


interface iArgs {
	authSystem: iAuthSystem
}


const tagIndicesStringToNumbers = (str?: string) =>
	str === undefined
		? []
		: str.split(',').map(s => parseInt(s))

export const createSearchSystem = ({ authSystem }: iArgs) => {
	const elasticFilterSystem = createElasticFilterSystem()
	const queryBuilder = createQueryBuilder(elasticFilterSystem)

	let initialQueryState = ''
	let initialFilterState = tagMapArray.map(() => false)

	const searchSystem = {
		// ...createSortSystem(),
		elasticFilterSystem,
		getInitialQueryState: () => initialQueryState,
		getInitialFilterState: () => initialFilterState,
		handleInitialUrlParams: (params: Object) => {
			initialQueryState = params['q'] || ''
			const tagIndices = tagIndicesStringToNumbers(params['tags'])
			initialFilterState = stateFromIndices(tagIndices)
		},
		requestNextPage: async () => {
			const urlQuery = queryBuilder.createNextPageSearchQuery()
			return await searchSystem.requestSearchResults(urlQuery)
		},
		updateMasterInterfaceUrl: (query: string, tags: iTag[]) => {
			dispatchEventWithArgs('search.setUrlParam', {
				key: 'q',
				value: query,
			})
			dispatchEventWithArgs('search.setUrlParam', {
				key: 'tags',
				value: tags.map((t) => t.key).toString(),
			})
		},
		requestNewSearchUsingFilterState: async (query: string, state: boolean[]) =>
			searchSystem.requestNewSearch(query, tagMapArray
				.filter((t, i) => state[i] === true))
		,
		requestNewSearch: async (query: string, tags: iTag[] = []) => {
			const urlQuery = queryBuilder.createNewSearchQuery(query, tags)
			return await searchSystem.requestSearchResults(urlQuery)
		},
		requestSearchResults: async (urlQuery: string) => {
			const response = await authSystem.authorizedFetchPath(`search/${urlQuery}`)
			const type = response.headers.get('content-type')
			switch (type) {
			case 'application/json':
				const searchResponse = await response.json() as iSearchResponse
				queryBuilder.setCurrentSearchParams(searchResponse)
				return searchResponse
			case 'text/html; charset=utf-8':
			default:
				throw new AuthenticationError('bad auth, remember for now search system needs to use cookies')
			}
		},
	}
	return searchSystem
}

export type iSearchSystem = ReturnType<typeof createSearchSystem>

