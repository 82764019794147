import React from 'react'
import { getApp } from 'app'
import { useSetRecoilState } from 'recoil'
import { masterInterfaceAtom } from './masterInterfaceState'
const { masterInterfaceSystem } = getApp()

export const MasterInterfaceSubscriber = () => {

	const set_masterInterfaceState = useSetRecoilState(masterInterfaceAtom)
	React.useEffect(() => {

		masterInterfaceSystem.onSelectSoundset
			.addListener(selectedSoundset =>
				set_masterInterfaceState(s => ({ ...s, selectedSoundset })))
		masterInterfaceSystem.onSelectMood
			.addListener(selectedMood =>
				set_masterInterfaceState(s => ({ ...s, selectedMood })))
		masterInterfaceSystem.onSelectElement
			.addListener(selectedElement =>
				set_masterInterfaceState(s => ({ ...s, selectedElement })))
		return () => { }
	}, [])

	return null
}