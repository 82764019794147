import React from 'react'
import { Grid, Box } from '@mui/material'
import { SearchBar } from './searchBar'
import { SearchResultsList } from './searchResults'
import { SearchEffects } from './SearchEffects'
import { UseStyles } from 'modules-ui/utility/styleUtility'
import { MasterInterfaceSubscriber } from 'modules-ui/masterInterface'
import { SimpleCircularProgress } from 'modules-ui/ui'



interface Props {
    height?: number
}
export const Search = (props: Props) => {
	const styles = useStyles()

	return (
		<>
			<MasterInterfaceSubscriber />
			{/*this react suspense only exists for tests, it is otherwise handled by embeddableLayout*/}
			<React.Suspense fallback={SimpleCircularProgress}>
				<SearchEffects />
				<div className={styles.container}>
					<Box m={2} />
					<SearchBar />
					<Box m={2} />
					<SearchResultsList {...props} />
				</div>
			</React.Suspense>
		</>
	)
}


const useStyles = UseStyles(theme => ({
	container: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1)
	}
}))
