import React from 'react'
import ReactDOM from 'react-dom'
import { AppBase } from 'modules-ui/AppBase'

interface Props {
    App: any
}

export const Root = ({ App }: Props) =>
	<AppBase>
		<App />
	</AppBase>


export const mountRoot = (App: React.ReactNode, id: string = 'root') =>
	ReactDOM.render(
		<Root {...{ App }} />,
		document.getElementById(id))