import React from 'react'
import { getApp } from 'app'

import { IconButton } from '@mui/material'
import { setCyid } from 'modules-ui/utility/testUtility'
import { SimpleTooltip } from 'modules-ui/ui'
import { iSearchResult } from 'modules-core/searchSystem'

import { SearchItemIcon } from './SearchItemIcon'
import { css } from '@emotion/css'

const { onlineApiSystem } = getApp()

interface iProps {
  item: iSearchResult
  buttonProps?: any
}

export const SearchItemPlay = ({ item, buttonProps }: iProps) => {
	//this will lose sync if the item finishes playing or is stopped in the master interface
	const [isPlaying, set_isPlaying] = React.useState(item.playing_now)

	const handleClick = () => {
		const playAction = onlineApiSystem.playActionMap[item.model_name]

		if (item.model_name !== 'OneshotElement') {
			playAction(item, isPlaying ? 'stop' : 'play')
			set_isPlaying((prev) => !prev)
		} else {
			playAction(item, 'play')
		}
	}

	return (
		<div className={containerStyle}>
			<div className={itemStyle}>
				{
					onlineApiSystem.playActionMap[item.model_name] && (
						<IconButton className={buttonStyle} {...setCyid('search-item-play-button')} size='small' onClick={handleClick} {...buttonProps}>
							<SearchItemIcon item={item} isPlaying={isPlaying} />
						</IconButton>
					)
				}
			</div>
		</div>
	)
}

const buttonStyle = css`
width:56px;
height:56px;
font-size: 1.8rem;
`


const containerStyle = css`
  display: flex;
  align-items: center;
  justify-items: center;
`

const itemStyle = css`
  width: 4em;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
`
