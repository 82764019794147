import { tagMapArray, iSearchResponse } from 'modules-core/searchSystem'
import { atom } from 'recoil'

export enum SearchResultsViewOption {
	Tile,
	List
}

export const searchViewAtom = atom({
	key: 'searchViewAtom',
	default: SearchResultsViewOption.List
})

export const searchLoadingAtom = atom({
	key: 'searchLoadingAtom',
	default: false
})
export const searchQueryAtom = atom<string>({
	key: 'searchQueryAtom',
	default: null
})

export const elasticFilterAtom = atom<boolean[]>({
	key: 'searchElasticFilterAtom',
	default: tagMapArray.map(t => false)
})

export const NextPageTrigger = atom<number>({
	key: 'NextPageTrigger',
	default: 0
})

export const SearchResponseState = atom<iSearchResponse>({
	key: 'searchResponseState',
	default: null
})
