import { iTag } from './tags/tagMap'
import { tagTypeMap } from './tags/tagTypeMap'



export const createElasticFilterSystem = () => {

	const tagToQueryParam = ({ name, tagType }: iTag) =>
		`${encodeURIComponent(tagTypeMap[tagType].name)}=${encodeURIComponent(name)}`

	const tagsToQueryParams = (tags: iTag[]) =>
		tags
			.map(tagToQueryParam)
			.join('&')


	return {
		tagToQueryParam,
		tagsToQueryParams
	}
}

export type iElasticFilterSystem = ReturnType<typeof createElasticFilterSystem>
