import { createEvent, iEvent, runWithTimeout } from 'modules-core/utility'
import { iOnlineApiSystem } from 'modules-core/onlineApiSystem'
import { iSearchResult, ModelNameActionMap } from 'modules-core/searchSystem'
import { iAuthSystem } from 'modules-core/authSystem'
import { iSearchSystem } from 'modules-core/searchSystem'
import {
	dispatchEventWithArgs,
	dispatchAndListen,
} from 'modules-core/eventSystem'
import config from 'modules-core/config'
import log from 'modules-core/log'

const logger = log.getLogger('masterInterfaceSystem')

interface iArgs {
	onlineApiSystem: iOnlineApiSystem
	authSystem: iAuthSystem
	searchSystem: iSearchSystem
	verboseLogs?: boolean
}

export const createMasterInterfaceSystem = ({ onlineApiSystem, authSystem, searchSystem, verboseLogs }: iArgs) => {

	let selectedSoundset: MasterInterface.Soundset | undefined
	let selectedMood: MasterInterface.Mood | undefined
	let selectedElement: MasterInterface.Element | undefined
	verboseLogs ??= true

	document.addEventListener('mi.selectedSoundset', (event: CustomEvent) => {
		const { params } = event.detail
		selectedSoundset = params
		system.onSelectSoundset.invoke(selectedSoundset)
	})
	document.addEventListener('mi.selectedMood', (event: CustomEvent) => {
		const { params } = event.detail
		selectedMood = params
		system.onSelectMood.invoke(selectedMood)
	})
	document.addEventListener('mi.selectedElement', (event: CustomEvent) => {
		const { params } = event.detail
		selectedElement = params
		system.onSelectElement.invoke(selectedElement)
	})
	document.addEventListener('mi.provideAuthToken', (event: CustomEvent) => {
		const { params } = event.detail
		config.token = params.authToken
	})
	document.addEventListener('mi.initialUrlParams', (event: CustomEvent) => {
		const { params } = event.detail
		searchSystem.handleInitialUrlParams(params)
	})

	const openItemFromIds = (args: { soundsetId: number; elementId?: number; moodId?: number }) =>
		dispatchEventWithArgs('search.openItem', args)

	const openElementFromId = (item: iSearchResult) =>
		openItemFromIds({ soundsetId: item.chapter_id, elementId: item.pk })

	const openActionMap: ModelNameActionMap = {
		'Chapter': (item) => openItemFromIds({ soundsetId: item.chapter_id }),
		'Mood': (item) => openItemFromIds({ soundsetId: item.chapter_id, moodId: item.pk }),
		'MusicElement': openElementFromId,
		'SFXElement': openElementFromId,
		'OneshotElement': openElementFromId
	}

	const addMoodToSoundset = async ({ pk }: { pk: number }) => {
		const mood = await onlineApiSystem.moodFromId(pk)
		dispatchEventWithArgs('search.addMoodToSoundset', mood)
	}
	const addElementToSoundset = async ({ pk }: { pk: number }) => {
		const element = await onlineApiSystem.elementFromId(pk)
		dispatchEventWithArgs('search.addElementToSoundset', element)
	}
	const addSampleToPlaylist = async ({ pk }: { pk: number }) => {
		const sample = await onlineApiSystem.sampleFromId(pk)
		dispatchEventWithArgs('search.addSampleToPlaylist', sample)
	}

	const addActionMap: ModelNameActionMap = {
		'Mood': addMoodToSoundset,
		'MusicElement': addElementToSoundset,
		'SFXElement': addElementToSoundset,
		'OneshotElement': addElementToSoundset,
		'SoundSample': addSampleToPlaylist
	}

	const system = {
		openItemFromIds,
		openElementFromId,
		openActionMap,
		addElementToSoundset,
		addSampleToPlaylist,
		addActionMap,
		getSelectedSoundset: () => selectedSoundset,
		getSelectedMood: () => selectedMood,
		getSelectedElement: () => selectedElement,
		onSelectElement: createEvent<MasterInterface.Element>(),
		onSelectSoundset: createEvent<MasterInterface.Soundset>(),
		onSelectMood: createEvent<MasterInterface.Mood>(),
		ping: async (timeout = 3000) => {
			return runWithTimeout(dispatchAndListen(
				'search.requestPing',
				'mi.ping'
			), timeout)
		},
		connectAndAuthorize: async () => {
			const isConnected = !!(await system.ping().catch((err) => { }))
			if (isConnected) {
				await dispatchAndListen(
					'search.requestAuthToken',
					'mi.provideAuthToken'
				)
				await dispatchAndListen(
					'search.requestInitialUrlParams',
					'mi.initialUrlParams'
				)
			} else if (verboseLogs) {
				logger.warn('Master Interface is not connected.')
			}
			return isConnected
		}
	}
	return system
}

export type iMasterInterfaceSystem = ReturnType<typeof createMasterInterfaceSystem>
