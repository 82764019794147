

import { createGlobalStyle } from '../utility/styleUtility'

export const baselineStyles = createGlobalStyle`
a { 
	color: inherit; 
}
em {
    font-style: normal;
    background-color: #20697A;
    padding: 2px;
    border-radius: 2px;
}
html, body, #__cy_root {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
}
.search {
	::-webkit-scrollbar {
		width: 8px;
		background-color: #303030;
	}
	::-webkit-scrollbar-track {
		background: rgba(48, 48, 48, 0);
	}
	::-webkit-scrollbar-thumb {
		height: 56px;
		background-color: #888;
		border: 1px solid transparent;
		// /* border-radius: 8px; */
		background-clip: content-box;
	}
	::-webkit-scrollbar-thumb:hover {
		background-color: #666;
	}
}
`
