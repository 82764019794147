import { createAudioSystem } from 'modules-core/audioSystem'
import { createAuthSystem } from 'modules-core/authSystem'
import { createElementSystem } from 'modules-core/elementSystem'
import { createMasterInterfaceSystem } from 'modules-core/masterInterfaceSystem'
import { createMoodSystem } from 'modules-core/moodSystem'
import { createOnlineApiSystem } from 'modules-core/onlineApiSystem'
import { createSampleSystem } from 'modules-core/sampleSystem'
import { createSearchSystem } from 'modules-core/searchSystem'
import { postMessageSystem } from 'modules-core/postMessageSystem'
import { SocketSystem } from 'modules-core/socket'
import { SyncSystem } from 'modules-core/syncSystem'
import { createUrlSystem } from 'modules-core/urlSystem'
import { createUuidSystem } from 'modules-core/uuidSystem'
import { createVisualizerSystem } from 'modules-core/visualizerSystem'
import { iApp } from '../appTypes'
import log from 'modules-core/log'

export const createBaseApp = () => {
	const urlSystem = createUrlSystem()
	const authSystem = createAuthSystem({ urlSystem })
	const searchSystem = createSearchSystem({ authSystem })
	const onlineApiSystem = createOnlineApiSystem({ authSystem })
	const masterInterfaceSystem = createMasterInterfaceSystem({ onlineApiSystem, authSystem, searchSystem })

	return {
		urlSystem,
		postMessageSystem,
		authSystem,
		searchSystem,
		onlineApiSystem,
		masterInterfaceSystem,
		log,
	} as Partial<iApp> as iApp
}

export const createPlayerApp = () => {
	const baseApp = createBaseApp()
	const {
		urlSystem,
		authSystem,
	} = baseApp

	const socketSystem = SocketSystem({urlSystem})

	const audioSystem = createAudioSystem({ urlSystem })
	const audioEffectSystem = audioSystem.effectSystem

	const uuidSystem = createUuidSystem()
	const sampleSystem = createSampleSystem({ audioSystem, uuidSystem })
	const elementSystem = createElementSystem({ authSystem, audioSystem, sampleSystem, uuidSystem })
	const moodSystem = createMoodSystem({ elementSystem })
	const visualizerSystem = createVisualizerSystem({ sampleSystem })
	const syncSystem = SyncSystem({audioSystem,elementSystem,sampleSystem,socketSystem})

	return {
		...baseApp,
		socketSystem,
		audioSystem,
		syncSystem,
		audioEffectSystem,

		sampleSystem,
		elementSystem,
		moodSystem,
		visualizerSystem,

		start: async () => {
			// TODO: Allow superusers to override the default effects.
			await audioSystem.init(true)
			await socketSystem.connect()
		}
	}  as iApp
}

// type BaseApp = ReturnType<typeof createBaseApp>
// type PlayerApp = ReturnType<typeof createPlayerApp>