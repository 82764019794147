



export const createInstanceGetter = <T>(createInstance: () => T) => {

	let instance: T

	const getter = {
		getInstance: () => {
			instance = createInstance()
			getter.getInstance = () => instance
			return instance
		}
	}
	return () => getter.getInstance()
}