import React from 'react'
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import { elasticFilterAtom, NextPageTrigger, searchLoadingAtom, searchQueryAtom, SearchResponseState, } from './searchState'
import { getApp } from 'app'
import { activeTagsFromState, tagMapArray } from 'modules-core/searchSystem/tags/tagMap'
import log from 'modules-core/log'

const logger = log.getLogger('search')

const { searchSystem } = getApp()



export const SearchEffects = () => {
	const set_searchLoading = useSetRecoilState(searchLoadingAtom)
	const [searchQuery, set_searchQuery] = useRecoilState(searchQueryAtom)
	const [searchFilters, set_searchFilters] = useRecoilState(elasticFilterAtom)
	const [searchResponse, set_searchResponse] = useRecoilState(SearchResponseState)
	const nextPageTrigger = useRecoilValue(NextPageTrigger)

	React.useEffect(() => {
		set_searchQuery(searchSystem.getInitialQueryState())
		set_searchFilters(searchSystem.getInitialFilterState())
	}, [])


	React.useEffect(() => {
		if (searchQuery === null)
			return
		const activeTags = activeTagsFromState(searchFilters)
		searchSystem.updateMasterInterfaceUrl(searchQuery, activeTags)
		handleRequestNewSearch()
	}, [searchQuery, searchFilters])

	React.useEffect(() => {
		handleRequestNextPage()
	}, [nextPageTrigger])

	const handleRequestNewSearch = async () => {
		set_searchLoading(true)
		const response = await searchSystem.requestNewSearchUsingFilterState(searchQuery, searchFilters)
			.catch(err => console.error(err))
		set_searchLoading(false)
		if (!response)
			return
		const responseQuery = response?.query || ''
		if (searchQuery !== responseQuery) {
			logger.debug(
				'Stale query, abandon. Old query:', responseQuery, 'New query:', searchQuery
			)
		} else
			set_searchResponse(response)
	}

	const handleRequestNextPage = async () => {
		if (!searchResponse)
			return
		const next = await searchSystem.requestNextPage()
		if (next === null)
			return
		set_searchResponse(prev => ({
			...next,
			results: [...prev.results, ...next.results]
		}))
	}
	return null
}
