import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { iResultModelName, iSearchResult } from 'modules-core/searchSystem'

import { TagKey } from 'modules-core/searchSystem'
import { tagIconMap } from '../tagIconMap'

import { faStop } from '@fortawesome/pro-solid-svg-icons/faStop'
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay'
import { css } from '@emotion/css'
import GradientIconBackground from 'modules-ui/ui/GradientIconBackground'
interface iProps {
  item: iSearchResult
  isPlaying?: boolean
}

export const modelMap: Partial<Record<iResultModelName, React.ReactNode>> = {
	Mood: <FontAwesomeIcon icon={tagIconMap[TagKey.Kind_Mood]} transform='right-0.5' />,
	SFXElement: <FontAwesomeIcon icon={tagIconMap[TagKey.Kind_SFX]} transform='right-0.5' />,
	MusicElement: <FontAwesomeIcon icon={tagIconMap[TagKey.Kind_Music]} transform='left-1 top-0.5' />,
	OneshotElement: <FontAwesomeIcon icon={tagIconMap[TagKey.Kind_Oneshot]} transform='right-1' />,
}

export const SearchItemIcon = ({ item, isPlaying }: iProps) => {
	//this will lose sync if the item finishes playing or is stopped in the master interface
	isPlaying ??= false
	return (
		<>
			{item.icon ? (
				<img className={iconStyle} alt='Play' src={item.icon} />
			) : (
			// REVIEW: item.model_name possibilities
				<GradientIconBackground variant={isPlaying? 'playing' : 'default'}>
					{modelMap[item.model_name] ??
            <FontAwesomeIcon icon={isPlaying ? faStop : faPlay} transform={isPlaying ? 'right-0.5' : 'right-2'} />
					}
				</GradientIconBackground>
			)}
		</>
	)
}

const iconStyle = css`
  width: 50px;
  height: 50px;
`
