
//these are to be bundled

export const createCyidQuery = (id: cyid) => `[data-cyid=${id}]`
export const setCyid = (id: cyid) => ({ 'data-cyid': id })


export type cyid =
	| 'nav-menu-button'
	| 'nav-avatar-button'
	| 'nav-search-button'
	| 'nav-home-button'

	| 'search-bar-input'
	| 'search-elastic-filter-item'
	| 'search-item-info-dialog'
	| 'search-item-info-button'
	| 'search-item-open-button'
	| 'search-item-play-button'
	| 'search-item-add-button'
	| 'search-results-view-tile'
	| 'search-results-view-list'

	| 'auth-email-input'
	| 'auth-password-input'
	| 'auth-token-input'
	| 'auth-remember-checkbox'
	| 'auth-submit-button'

	| 'controls-stop-all'
	| 'controls-global-volume'
	| 'controls-local-volume'
	| 'controls-oneshot-volume'

	| 'trigger-test-start-time'
	| 'trigger-test-sine-1'
	| 'trigger-test-sine-2'
	| 'trigger-test-sine-3'
	| 'trigger-test-sample-1'
	| 'trigger-test-sample-2'
	| 'trigger-test-sample-3'
	| 'trigger-test-element-1'
	| 'trigger-test-element-2'
	| 'trigger-test-element-3'
	| 'trigger-test-mood-1'
	| 'trigger-test-mood-2'
	| 'trigger-test-mood-3'
	| 'trigger-test-socket-mood'
	| 'trigger-test-socket-element'
	| 'trigger-test-socket-stop-all'
	| 'trigger-test-sync-stop-all'
	| 'trigger-test-sync-start-elements'
	| 'trigger-test-sync-start-mood'

	| 'oneshot-test-start'
	| 'oneshot-test-stop'
	| 'oneshot-test-stop'
	| 'oneshot-test-reverb-select'
	| 'oneshot-test-reverb-item'
	| 'oneshot-test-angle'
	| 'oneshot-test-distance'
	| 'oneshot-test-speed'
	| 'oneshot-test-sample-select'
	| 'oneshot-test-sample-item'
	| 'oneshot-test-frequency'

	| 'playlist-test-playlist-select'
	| 'playlist-test-playlist-item'
	| 'playlist-test-type-select'
	| 'playlist-test-type-item'
	| 'playlist-test-order-select'
	| 'playlist-test-order-item'
	| 'playlist-test-repeat'
	| 'playlist-test-loop'
	| 'playlist-test-element-delay'
	| 'playlist-test-sample-delay'
	| 'playlist-test-sample-delay-behavior-select'
	| 'playlist-test-sample-delay-behavior-item'
	| 'playlist-test-start'
	| 'playlist-test-stop'
	| 'playlist-test-element-volume'

	| 'audio-effects-add-dialog'
	| 'audio-effects-add-name'
	| 'audio-effects-add-type'
	| 'audio-effects-add-type-item'
	| 'audio-effects-add-submit'
	| 'audio-effects-remove'
	| 'audio-effects-build-tree'
	| 'audio-effects-upload-convolver'

	| 'audio-presets-remove-spatializer'
	| 'audio-presets-add-spatializer'

	| 'snapshot-inspector-download'
	| 'snapshot-inspector-upload'
	| 'snapshot-inspector-clear'
	| 'snapshot-inspector-submit'
	| 'snapshot-inspector-input'
	| 'snapshot-inspector-output'

	| 'panels-tab-select'
	| 'panels-tab-search'
	| 'panels-tab-player'