import { css } from '@emotion/css'
import { CircularProgress } from '@mui/material'
import React from 'react'

export const SimpleCircularProgress = () => {
	return <div className={style}>
		<CircularProgress />
	</div>
}


const style = css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
