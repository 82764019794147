import { Tooltip, Zoom } from '@mui/material'
import React from 'react'

interface iProps {
    title: string
    children?: React.ReactElement
}

export const SimpleTooltip = ({ title, children }: iProps) => {
	return (
		<Tooltip
			arrow
			title={title}
			placement="top"
			TransitionComponent={Zoom}
			// disableFocusListener={true}
		>
			{children}
		</Tooltip>
	)
}
