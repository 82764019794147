import { Button, Typography } from '@mui/material'
import { activeTagsFromState, TagKey, tagMapArray } from 'modules-core/searchSystem'
import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { elasticFilterAtom, searchQueryAtom, SearchResponseState } from '../searchState'

export const SearchResultsEndMessage = () => {
	const [filterState, set_filterState] = useRecoilState(elasticFilterAtom)
	const [searchQuery, set_searchQuery] = useRecoilState(searchQueryAtom)
	const searchResponse = useRecoilValue(SearchResponseState)

	const handleClearFilters = () =>
		set_filterState(prev => prev.map(v => false))

	const handleShowAllNowPlaying = () => {
		set_searchQuery('')
		set_filterState(() => tagMapArray.map(t =>
			t.key === TagKey.Playing_NowPlaying
				? true
				: false
		))
	}

	const activeTags = activeTagsFromState(filterState).map(t => t.key)
	const nowPlayingExists = activeTags.includes(TagKey.Playing_NowPlaying)
	const nowPlayingAndOther = nowPlayingExists && (searchQuery || activeTags.length > 1)

	const getEndButton = () => {
		if (nowPlayingAndOther)
			return <Typography align='center' variant="body1">
                Looking for all now playing?
				<Button color="primary" onClick={handleShowAllNowPlaying}>show all</Button></Typography>
		else if (activeTags.length > 0)
			return <Typography align='center' variant="body1">
                Looking for more?
				<Button color="primary" onClick={handleClearFilters}>clear filters</Button>
			</Typography>
		else
			return null
	}

	return (<>
		<Typography align='center' variant="body1" margin={'10px 0 0'} fontSize={'1.5rem'}>{
			searchResponse.results.length === 0
				? 'No Results...'
				: 'No More Results...'
		}</Typography>
		<br />
		{getEndButton()}
	</>
	)
}
