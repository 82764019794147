import { css } from '@emotion/css'
import { Typography } from '@mui/material'
import { getTagFromCategories, iSearchResult, TagKey } from 'modules-core/searchSystem'
import { SimpleHtmlTypography } from 'modules-ui/ui/SimpleHtmlTypography'
import React from 'react'
import { TagIcon } from './SearchItemTags'
import { highlightOrRegular } from './utility'

interface iProps {
  item: iSearchResult
}

interface iItemProps {
  tagKey: TagKey
  text: string
}
export const SummaryItem = ({ tagKey, text }: iItemProps) => {
	return (
		<div className={itemStyle}>
			{tagKey ? <TagIcon tagKey={tagKey} /> : null}
			<SimpleHtmlTypography className={textStyle} text={text} />
		</div>
	)
}

export const SearchItemSummary = ({ item }: iProps) => (
	<div>
		{item.adventure_group_title && (
			<SummaryItem
				text={
					item.adventure_group_subtitle
						? `${highlightOrRegular(item, 'adventure_group_title')} - ${highlightOrRegular(item, 'adventure_group_subtitle')}`
						: `${highlightOrRegular(item, 'adventure_group_title')}`
				}
				tagKey={getTagFromCategories(item.category)}
			/>
		)}
		{item.chapter_title && (
			<SummaryItem text={highlightOrRegular(item, 'chapter_title')} tagKey={TagKey.Kind_Soundset} />
		)}
		{item.adventure_title && (
			<SummaryItem text={highlightOrRegular(item, 'adventure_title')} tagKey={TagKey.Kind_Adventure} />
		)}
	</div>
)

const containerStyle = css`
  display: flex;
`

const itemStyle = css`
  display: flex;
  /* REVIEW: align-items? Previously 'alignItem' */
  align-item: center;
`

const textStyle = css`
  font-size: 0.875em;
`
