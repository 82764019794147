import config from 'modules-core/config'
import eventSystem from './eventSystem'

interface EventArguments {
	[key: string]: any
}

export const dispatchEventWithArgs = (
	eventName: string,
	params?: EventArguments
): void => {
	const detail = {
		params,
		token: config.token,
	}

	eventSystem.dispatch(eventName, detail)
}

export const dispatchAndListen = (
	dispatchEventName: string,
	responseEventName: string,
	requestData?: any
) => {
	return new Promise((resolve, reject) => {
		const responseListener = (event: CustomEvent) => {
			const responseData = event.detail

			document.removeEventListener(responseEventName, responseListener)
			resolve(responseData)
		}

		document.addEventListener(responseEventName, responseListener)
		dispatchEventWithArgs(dispatchEventName, requestData)
	})
}
