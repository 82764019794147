

export interface iTagType {
	name: string
	displayName: string
	key: TagTypeKey
}

export type iTagTypeMap = Record<TagTypeKey, iTagType>

export enum TagTypeKey {
	Genre,
	Kind,
	Status,
	Library,
	Playing
}


export const tagTypeMap: iTagTypeMap = {
	[TagTypeKey.Genre]: {
		name: 'genre',
		displayName: 'Genre',
		key: TagTypeKey.Genre
	},
	[TagTypeKey.Kind]: {
		name: 'kind',
		displayName: 'Type',
		key: TagTypeKey.Kind
	},
	[TagTypeKey.Library]: {
		name: 'library',
		displayName: 'Library',
		key: TagTypeKey.Library
	},
	[TagTypeKey.Status]: {
		name: 'status',
		displayName: 'Status',
		key: TagTypeKey.Status
	},
	[TagTypeKey.Playing]: {
		name: 'playing',
		displayName: 'Now Playing',
		key: TagTypeKey.Playing
	}
}

export const tagTypeMapArray = Object.values(tagTypeMap)



// "Moods,Music,Loops,SFX,Oneshots,Samples"