import { css } from '@emotion/css'
import { styled } from '@mui/material/styles'
import { Collapse, Divider } from '@mui/material'
import { iSearchResult } from 'modules-core/searchSystem'
import { SimpleExpandButton } from 'modules-ui/ui/SimpleExpandButton'
import React from 'react'
import { SearchItemAdd } from './SearchItemAdd'
import { SearchItemInfoContent } from './SearchItemInfoContent'
import { SearchItemPlay } from './SearchItemPlay'
import { SearchItemTags } from './SearchItemTags'
// import { stripHtml } from "string-strip-html"
import { SearchItemTitle } from './SearchItemTitle'

const StyledExpandButton = styled(SimpleExpandButton)({
	fontSize: '2.4rem'
}) as typeof SimpleExpandButton


interface iProps {
	item: iSearchResult
	startOpen?: boolean
}
export const SearchItemList = ({ item, startOpen }: iProps) => {
	startOpen ??= false
	const [detailsOpen, set_detailsOpen] = React.useState(startOpen)

	return (
		<>
			<div className={containerStyle}>
				<SearchItemPlay item={item} />
				<SearchItemTitle item={item} />
				<div className={iconsStyle}>
					{SearchItemTags({ item })}
					<SearchItemAdd item={item} />
					<StyledExpandButton open={detailsOpen} set_open={set_detailsOpen} variant='search' />
				</div>
			</div>
			<Collapse in={detailsOpen}>
				<SearchItemInfoContent item={item} />
			</Collapse>
			<Divider />
		</>
	)
}

const containerStyle = css`
  display: flex;
  align-items: stretch;
  min-height: 4em;
  font-size: 0.9em;
`

const iconsStyle = css`
  display: flex;
  align-items: center;
`

const spacerStyle = css`
  flex-grow: 1;
`
