import { iSearchResult } from '../searchTypes'
import { iTag, TagKey, tagMap } from './tagMap'
import { getTagFromCategories, isPlayable, resultKindMap, resultStatusMap } from './tagMatcherMaps'
import { TagTypeKey } from './tagTypeMap'







export const getTagsForResult = (result: iSearchResult) =>
	Object.values(tagMatchLookup)
		.reduce((arr, func) => {
			func(result, arr)
			return arr
		}, [] as TagKey[])


type iTagFunc = (result: iSearchResult, tags: TagKey[]) => void
const tagMatchLookup: Record<TagTypeKey, iTagFunc> = {
	[TagTypeKey.Genre]: (result, tags) => {
		if (result.model_name === 'SoundSample')
			return
		const key = getTagFromCategories(result.category)
		if (key !== undefined)
			tags.push(key)
	},
	[TagTypeKey.Kind]: (result, tags) => {
		tags.push(resultKindMap[result.model_human_name])
		// if (result.adventure_title)
		//	 tags.push(TagKey.Kind_Adventure)
		// if (result.chapter_title)
		//	 tags.push(TagKey.Kind_Soundset)
	},

	[TagTypeKey.Playing]: (result, tags) => {
		if (result.playing_now)
			tags.push(TagKey.Playing_NowPlaying)
	},
	[TagTypeKey.Library]: (result, tags) => {
		if (result.available_to_play)
			tags.push(TagKey.Library_AvailableToPlay)
		if (result.available_to_buy)
			tags.push(TagKey.Library_AvailableToBuy)
	},
	[TagTypeKey.Status]: (result, tags) => {
		const key = resultStatusMap[result.status]
		if (key !== undefined)
			tags.push(key)
	},
}