import { createQueryParam, createQueryFromParams } from 'modules-core/utility'
import { iTag } from './tags'
import { iElasticFilterSystem } from './elasticFilterSystem'
import { iSearchResponse } from './searchTypes'



export const SEARCH_RESULTS_PER_PAGE = 100


interface iArgs {
	elasticFilterSystem: iElasticFilterSystem
}
export const createQueryBuilder = (elasticFilterSystem: iElasticFilterSystem) => {

	let priorPage = 0
	let priorQueryHash = ''
	let priorStrParam = null
	let priorTagParams = ''

	const queryBuilder = {
		setCurrentSearchParams: (searchResponse: iSearchResponse) => {
			priorQueryHash = searchResponse.query_hash
			priorPage = searchResponse.page
		},
		createNewSearchQuery: (str: string, tags: iTag[] = []) => {
			const queryStrParam = createQueryParam('q', str)
			const tagParams = elasticFilterSystem.tagsToQueryParams(tags)
			const params = []
			params.push(createQueryParam('f', 'json'))
			if (priorQueryHash) params.push(createQueryParam('pqh', priorQueryHash))
			params.push(queryStrParam)
			if (tagParams !== '') params.push(tagParams)
			params.push(createQueryParam('pp', SEARCH_RESULTS_PER_PAGE))

			priorStrParam = queryStrParam
			priorTagParams = tagParams

			return createQueryFromParams(params)
		},
		createNextPageSearchQuery: () => {
			const params = []
			params.push(createQueryParam('f', 'json'))
			params.push(createQueryParam('pqh', priorQueryHash))
			params.push(priorStrParam)
			if (priorTagParams !== '') params.push(priorTagParams)
			params.push(createQueryParam('p', priorPage + 1))
			params.push(createQueryParam('pp', SEARCH_RESULTS_PER_PAGE))
			return createQueryFromParams(params)
		}

	}

	return queryBuilder
}