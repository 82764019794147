import { iSearchResult } from 'modules-core/searchSystem'

export const highlightOrRegular = (item: iSearchResult, text: string) => {
	if (typeof item.meta.highlight[text] === 'string')
		return item.meta.highlight[text]
	if (Array.isArray(item.meta.highlight[text]))
		return item.meta.highlight[text].join(', ')
	if (typeof item[text] === 'string')
		return item[text]
	if (Array.isArray(item[text]))
		return item[text].join(', ')
}
