import { iAuthSystem } from 'modules-core/authSystem'
import { elementModelHumanNames, iResultModelHumanName, iResultModelName, iSearchResult, ModelNameActionMap, ModelNamePlayMap, moodModelHumanName, PlayStopCommand, sampleModelHumanName } from 'modules-core/searchSystem'
import { OnlineApi } from '.'


interface iArgs {
	authSystem: iAuthSystem
}

type itemType = 'elements' | 'samples' | 'moods'
// | "soundsets" | "moods"


const getItemFromFilterUrl = (type: itemType) =>
	`online/frontend-api/${type}/0/filter/`


const getItemType = (name: iResultModelHumanName): itemType => {
	if (name === sampleModelHumanName)
		return 'samples'
	else if (name === moodModelHumanName)
		return 'moods'
	else if (elementModelHumanNames.includes(name))
		return 'elements'
	else
		throw new Error(`Cant play this item type: ${name}`)
}

export const createOnlineApiSystem = ({ authSystem }: iArgs) => {


	const itemFromId = async (type: itemType, id: number) => {
		const response = await authSystem.authorizedFetchPath(getItemFromFilterUrl(type),
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json;charset=UTF-8' },
				body: JSON.stringify({ 'id:in': [id] })
			})
		const json = await response.json()
		return json[0]
	}


	const playItemFromPk = async (pk: number, type: itemType, command: PlayStopCommand = 'play') => {
		const url = `online/frontend-api/${type}/${pk}/${command}/`
		authSystem.authorizedFetchPath(url)
	}

	const playItemFromSearch = async (item: iSearchResult, command: PlayStopCommand = 'play') =>
		system.playItemFromPk(item.pk, getItemType(item.model_human_name), command)

	const playActionMap: ModelNamePlayMap = {
		'Mood': playItemFromSearch,
		'SoundSample': playItemFromSearch,
		'MusicElement': playItemFromSearch,
		'SFXElement': playItemFromSearch,
		'OneshotElement': playItemFromSearch
	}

	const system = {
		playActionMap,
		playItemFromSearch,
		playItemFromPk,
		moodFromId: async (id: number) => await itemFromId('moods', id) as OnlineApi.Mood,
		elementFromId: async (id: number) => await itemFromId('elements', id) as OnlineApi.Element,
		sampleFromId: async (id: number) => await itemFromId('samples', id) as OnlineApi.Sample,

		allAuthorizedSoundsets: async () => {
			const response = await authSystem
				.authorizedFetchPath('online/frontend-api/soundsets/')
			return await response.json() as OnlineApi.Soundset[]
		},
		allAuthorizedSoundsetIds: async () => {
			const response = await authSystem
				.authorizedFetchPath('online/frontend-api/soundsets/0/filter/?')
			return await response.json() as OnlineApi.SoundsetUuidArrayResponse
		},
		moodsFromSoundsetUuuid: async (uuid: string) => {
			const response = await authSystem
				.authorizedFetchPath(`online/frontend-api/moods/?soundset__uuid=${uuid}`)
			return await response.json() as OnlineApi.Mood[]
		}

		// soundsetFromId: async (id: number) => itemFromId("soundsets", id),
	}
	return system
}

export type iOnlineApiSystem = ReturnType<typeof createOnlineApiSystem>
