import React from 'react'
import { Grid, Typography } from '@mui/material'
import { SearchElasticFilter } from './SearchElasticFilter'
import { SearchInput } from './SearchInput'
import { useRecoilValue } from 'recoil'
import { iSearchResponse } from 'modules-core/searchSystem'
import { SearchResponseState } from '../searchState'


const getSummaryText = (response?: iSearchResponse) => {
	if (!response)
		return ' '
	if (!response.more_pages)
		return `${response.results.length} results`
	return `${response.results.length} of ~${response.count} results`
}


export const SearchBar = () => {
	const searchResponse = useRecoilValue(SearchResponseState)


	return (
		<Grid container>
			<Grid item xs={12}>
				<SearchInput />
			</Grid>
			<Grid item xs={12}>
				<Typography fontSize={'1.6rem'}>
					{getSummaryText(searchResponse)}
				</Typography>
			</Grid>
			<Grid item xs={12} className="search-filters">
				<SearchElasticFilter />
			</Grid>
		</Grid>
	)
}
