import '../common/dumbStubs'
import '../common/setBaseApp'
import { mountRoot } from 'app/common/mountRoot'
import { Search } from 'modules-ui/search'
import config from 'modules-core/config'
import log from 'modules-core/log'
import syrinscape from 'app/common/syrinscape'

const logger = log.getLogger('search')

export const createSearch = () => {
	logger.info('createSearch()')
	return {
		init: async (token: string) => {
			await config.init('default', { token })
			mountRoot(Search, 'elastic-search-panel')
		},
	}
}

syrinscape.search ??= createSearch()

export const search = syrinscape.search
export default search
