



export const createQueryParam = (key: string, value: string | number) =>
	`${encodeURIComponent(key)}=${encodeURIComponent(value)}`

export const createQueryFromParams = (params: string[]) =>
	'?' + params.join('&')


export const objectToQueryParams = (request: Object) =>
	Object.entries(request)
		.map(([key, value]) => [key, encodeURIComponent(value)])
		.map(([key, value]) => `${key}=${value}`)
		.join('&')
		.split('%25')//dont encode percent sign
		.join('%')
