import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { iSearchResult, getTagsForResult, tagMap, TagKey } from 'modules-core/searchSystem'
import { tagIconMap } from '../tagIconMap'
import { css } from '@emotion/css'
import { SimpleTooltip } from 'modules-ui/ui'

interface iProps {
  item: iSearchResult
  size?: 'sm' | 'lg'
}

interface iTagProps {
  size?: 'sm' | 'lg'
  tagKey: TagKey
}
export const TagIcon = ({ tagKey, size }: iTagProps) => {
	size ??= 'sm'
	return (
		<SimpleTooltip title={tagMap[tagKey].name}>
			<div className={tagStyle}>
				<FontAwesomeIcon icon={tagIconMap[tagKey]} size={size} />
			</div>
		</SimpleTooltip>
	)
}

export const SearchItemTags = ({ item, size }: iProps) => {
	size ??= 'sm'
	const tagKeys = getTagsForResult(item)
		.filter((tagKey) => tagKey !== TagKey.Playing_NowPlaying)
	return (
		<>
			{' '}
			{tagKeys.map((tagKey) => (
				<TagIcon key={tagKey} tagKey={tagKey} size={size} />
			))}
		</>
	)
}

const tagStyle = css`
  display: inline-block;
  text-align: center;
  width: 1.5em;
`
